import dayjs from "dayjs";
var localizedFormat = require("dayjs/plugin/localizedFormat");
var ru = require("dayjs/locale/ru");
dayjs.extend(localizedFormat);
export var dateConvertor = function (date) {
    var currentDate = dayjs();
    var days = currentDate.date() - date.date();
    var isCurrentMonth = (currentDate === null || currentDate === void 0 ? void 0 : currentDate.month()) === (date === null || date === void 0 ? void 0 : date.month());
    var isCurrentYear = (currentDate === null || currentDate === void 0 ? void 0 : currentDate.year()) === (date === null || date === void 0 ? void 0 : date.year());
    var hours = Math.ceil((currentDate === null || currentDate === void 0 ? void 0 : currentDate.hour()) - (date === null || date === void 0 ? void 0 : date.hour()));
    var minutes = Math.ceil((currentDate === null || currentDate === void 0 ? void 0 : currentDate.minute()) - (date === null || date === void 0 ? void 0 : date.minute()));
    var numb_h = [1, 21];
    var numb_h2 = [2, 3, 4, 22, 23];
    var numb_m = [1, 21, 31, 41, 51];
    var numb_m2 = [2, 3, 4, 22, 23, 24, 32, 33, 34, 42, 43, 44, 52, 53, 54];
    var word_h = "";
    numb_h.forEach(function (h) {
        if (hours === h)
            word_h = "час";
    });
    numb_h2.forEach(function (h2) {
        if (hours === h2)
            word_h = "часа";
    });
    if (word_h === "")
        word_h = "часов";
    var word_m = "";
    numb_m.forEach(function (m) {
        if (minutes === m)
            word_m = "минута";
    });
    numb_m2.forEach(function (m2) {
        if (minutes === m2)
            word_m = "минуты";
    });
    if (word_m === "")
        word_m = "минут";
    if (days === 0 && isCurrentMonth && isCurrentYear) {
        if (hours === 0) {
            return "".concat(minutes, " ").concat(word_m, " \u043D\u0430\u0437\u0430\u0434");
        }
        return "".concat(hours, " ").concat(word_h, " \u043D\u0430\u0437\u0430\u0434");
    }
    return date.locale(ru).format("LLL").replace("г.", "").replace(",", "");
};
