import * as yup from "yup";
var passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
/* const usernameRules = /^[a-zA-Z0-9_-]{3,16}$/;
const emailValidation = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i; */
var fullnameRules = /[A-Za-zА-Яа-яЁё]+(\s+[A-Za-zА-Яа-яЁё]+)?$/;
/* const phoneRules =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/; */
var specialLettersRegex = /^[A-Za-zА-Яа-яЁё\s()_:"']+$/;
export var registrationSchemaForUser = yup.object().shape({
    // username: yup.string().required("Необходимое поле"),
    first_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Имя должно содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    last_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Фамилия должно содержать только буквы английского или русского алфавита"),
    password: yup
        .string()
        .required("Необходимое поле")
        .min(8)
        .matches(passwordRules, { message: "см.Подсказку правил пароля справа" }),
    confirm_password: yup
        .string()
        .oneOf([yup.ref("password")], "Пароли не совпадают")
        .required("Повторите пароль правильно"),
});
export var registrationSchemaForExpert = yup.object().shape({
    first_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Имя должно содержать только буквы английского или русского алфавита"),
    last_name: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Фамилия должно содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    special: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Должность должна содержать только буквы английского или русского алфавита"),
    work: yup
        .string()
        .trim()
        .required("Необходимое поле")
        .matches(specialLettersRegex, "Работа должна содержать только буквы английского или русского алфавита"),
    phone: yup.string().trim().required("Необходимое поле"),
});
export var registrationSchemaForCompany = yup.object().shape({
    ceo: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Ceo должна содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    name: yup
        .string()
        .max(30)
        .required("Введите полное имя вашей компании. Минимальная длина 5 символов")
        .matches(fullnameRules, "Имя компании должна содержать только буквы английского или русского алфавита"),
    phone: yup.string().trim().required("Необходимое поле"),
});
export var registrationSchemaForStartups = yup.object().shape({
    ceo: yup
        .string()
        .required("Необходимое поле")
        .matches(fullnameRules, "Ceo должна содержать только буквы английского или русского алфавита"),
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    name: yup
        .string()
        .max(30)
        .required("Введите полное имя вашей компании. Минимальная длина 5 символов")
        .matches(fullnameRules, "Имя компании должна содержать только буквы английского или русского алфавита"),
    phone: yup.string().trim().required("Необходимое поле"),
});
export var authenticationSchema = yup.object().shape({
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    password: yup.string().required("Необходимое поле"),
});
export var registrationDiscountSchema = yup.object().shape({
    who: yup.string().required("Необходимое поле"),
    email: yup
        .string()
        .trim()
        .required("Необходимое поле"),
    name: yup.string().min(5).max(20).required("Введите полное имя вашей компании. Минимальная длина 5 символов"),
    phone: yup.string().trim().required("Необходимое поле"),
});
