export function makeYoutubeVideolinkLegit(videoLink) {
    if (videoLink.includes('youtu.be')) {
        return "https://www.youtube.com/embed/".concat(videoLink.slice(videoLink.lastIndexOf('/') + 1, videoLink.indexOf('?')));
    }
    var videoIdStartIndex = videoLink.indexOf('v=') + 2;
    var videoIdEndIndex = videoLink.indexOf('&', videoIdStartIndex);
    var videoId = videoLink.slice(videoIdStartIndex, videoIdEndIndex !== -1 ? videoIdEndIndex : undefined);
    var embeddedLink = "https://www.youtube.com/embed/".concat(videoId);
    return embeddedLink;
}
export function makeRutubeVideoLinkLegit(videoLink) {
    if (videoLink.includes('rutube.ru')) {
        var videoIdMatch = videoLink.match(/(?:rutube\.ru\/video\/|video\/|v\/)([0-9a-f]{32})/);
        if (videoIdMatch && videoIdMatch[1]) {
            var videoId = videoIdMatch[1];
            return "https://rutube.ru/play/embed/".concat(videoId);
        }
    }
    return '';
}
export function makeVimeoVideoLinkLegit(videoLink) {
    if (videoLink.includes('vimeo.com')) {
        var videoIdMatch = videoLink.match(/(?:vimeo\.com\/)(\d+)/);
        if (videoIdMatch && videoIdMatch[1]) {
            var videoId = videoIdMatch[1];
            return "https://player.vimeo.com/video/".concat(videoId);
        }
    }
    return '';
}
